import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";
import Select2 from 'vue3-select2-component'
import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';
import print from 'vue3-print-nb'
import QRCodeVue3 from "qrcode-vue3";
import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import 'vue-loading-overlay/dist/css/index.css';
AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

createApp(App)
    .use(store)
    .use(router)
    .component('apexchart',VueApexCharts)
    .use(BootstrapVue3)
    .component('select2', Select2)
    .component('qrCode', QRCodeVue3)
    .use(print)
    .component(VueFeather.type, VueFeather)

    .use(Maska)
    .use(i18n)
    .use(vClickOutside).mount('#app');